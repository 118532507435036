<template>
  <v-navigation-drawer
    v-model="drawer"
    class="grey lighten-4 pa-7 pr-1"
    fixed
    right
    temporary
    width="508"
  >
    <template v-if="details">
      <v-row
        class="pr-2"
        justify="end"
        no-gutters
      >
        <v-col cols="1">
          <v-btn
            icon
            @click="drawer = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <k-subtitle>{{ details.initials }} {{ details.name }}</k-subtitle>
      </v-row>
      <v-row align="center">
        <v-col
          class="pa-0"
          cols="1"
        >
          <ShiftCandidateStatus
            :size="21"
            :value="details.shiftCandidateStatusId"
            class="ml-4"
          />
        </v-col>
        <v-col cols="5">
          <v-row no-gutters>
            <v-col>
              <div class="caption text--secondary font-italic">
                {{ $t(`shift.candidateFields.status`) }}
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <div class="body2 text--primary">
                {{ $t(`shift.candidateStatus.${details.shiftCandidateStatusId}`) }}
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <ShiftCandidateHandlingButton
            :candidate-id="candidateId"
            :match-mode-id="matchModeId"
            :shift-status-id="shiftStatusId"
            :shift-candidate-status-id="details.shiftCandidateStatusId"
            :shift-handling-id="shiftHandlingId"
            :shift-id="parseInt($route.params.shiftId)"
            class="float-right ml-auto"
            @change="handleStatusChange"
          />
        </v-col>
      </v-row>
      <v-row>
        <!-- V-col has flexbox styles, however is not a flex box itself (thus d-flex). Justify-center works on flexbox/grid only -->
        <v-col
          class="d-flex justify-center pt-5"
          cols="5"
        >
          <VImg
            v-if="hasProfilePicture"
            :src="`${details.profilePicture.url}&type=small`"
            :lazy-src="`${details.profilePicture.url}&type=lazy`"
            :style="{ maxWidth: '142px', maxHeight: '142px', border: '1px solid', borderRadius: '30px' }"
            aspect-ratio="1"
          />
          <v-icon
            v-else
            :style="{ border: '1px solid', borderRadius: '30px' }"
            size="142"
          >
            $profile
          </v-icon>
        </v-col>
        <VDivider
          class="mx-5"
          inset
          vertical
        />
        <v-col cols="5">
          <KTextDisplay
            v-if="details.contactDetail.phone"
            :label="$t('contactDetail.phone')"
            :value="details.contactDetail.phone"
            icon="$phone"
          />
          <KTextDisplay
            v-if="details.contactDetail.email"
            :label="$t('contactDetail.email')"
            :value="details.contactDetail.email"
            icon="$email"
          />
          <k-text-display
            v-if="details.address"
            :label="$t('candidate.fields.address')"
            icon="$address"
          >
            <AddressDisplay :address="details.address" />
          </k-text-display>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card
            class="transparent"
            flat
            min-height="300"
            width="100%"
          >
            <v-tabs
              v-model="tab"
              background-color="transparent"
            >
              <v-tab>{{ $t('global.details') }}</v-tab>
              <v-tab v-if="details.experiences.length > 0 && details.educations.length > 0">
                {{ $t('candidate.background.experience') }}
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tab"
              class="transparent"
              style="width: 100%"
            >
              <v-tab-item>
                <v-row
                  class="py-3"
                  no-gutters
                >
                  <v-col cols="5">
                    <KTextDisplay
                      :icon="genderIcon"
                      :label="$t('candidate.fields.gender')"
                      :value="$t(`candidate.genderOptions.${details.genderId}`)"
                    />
                    <KTextDisplay
                      :label="$t('contactDetail.age')"
                      :value="age"
                      icon="$birthday"
                    />
                    <KTextDisplay
                      :label="$t('contactDetail.residence')"
                      :value="details.address.city"
                      icon="$address"
                    />
                    <KTextDisplay
                      v-if="details.positions"
                      :label="$t('candidate.background.fields.educationName')"
                      :value="details.positions.map(position => position.name).join(', ')"
                      icon="$position"
                    />
                  </v-col>
                  <VDivider
                    class="mx-5"
                    inset
                    vertical
                  />
                  <v-col cols="5">
                    <div class="body-2 text--secondary font-italic">
                      {{ $t('candidate.fields.maxTravelingTime') }}
                    </div>
                    <KTextDisplay
                      :label="$t('candidate.fields.transportationCar')"
                      :value="`${details.travelingTimeCar} ${$tc('global.timeUnits.minute', details.travelingTimeCar)}`"
                      icon="$car"
                    />
                    <KTextDisplay
                      :label="$t('candidate.fields.transportationPublic')"
                      :value="`${details.travelingTimePublic} ${$tc('global.timeUnits.minute', details.travelingTimePublic)}`"
                      icon="$train"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row
                  class="px-3"
                  no-gutters
                >
                  <v-col>
                    <v-row
                      v-if="details.experiences.length > 0"
                      class="font-weight-bold cursor--pointer"
                      @click="showExperience = !showExperience"
                    >
                      <v-col
                        align-self="center"
                        class="flex-grow-1"
                      >
                        {{ $t('candidate.background.experience') }}
                      </v-col>
                      <v-col class="flex-grow-0">
                        <v-btn icon>
                          <v-icon
                            :class="showExperience && 'open'"
                            class="expand-icon"
                          >
                            $expand
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-expand-transition>
                      <div v-if="showExperience">
                        <v-row
                          v-for="(experience, index) in details.experiences"
                          :key="`experience-${index}`"
                          cols="12"
                        >
                          <v-col
                            class="py-0"
                            cols="12"
                          >
                            <div>{{ experience.position }}</div>
                            <div>{{ experience.employer }}</div>
                            <div>
                              {{ experience.startYear }} -
                              {{ experience.endYear || $t('candidate.background.presentDay') }}
                              {{
                                formatExperienceTimeInTextI18n(`${experience.startYear}-${experience.startMonth}-01`, `${experience.endYear}-${experience.endMonth}-01`)
                              }}
                            </div>
                            <VDivider
                              v-if="index+1 < details.experiences.length"
                              class="my-3"
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </v-expand-transition>
                    <v-row
                      v-if="details.educations.length > 0"
                      class="font-weight-bold cursor--pointer"
                      @click="showEducation = !showEducation"
                    >
                      <v-col align-self="center">
                        {{ $t('candidate.background.education') }}
                      </v-col>
                      <v-col class="flex-grow-0">
                        <v-btn icon>
                          <v-icon
                            :class="showEducation && 'open'"
                            class="expand-icon"
                          >
                            $expand
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-expand-transition>
                      <div v-if="showEducation">
                        <v-row
                          v-for="(education, index) in details.educations"
                          :key="`education-${index}`"
                          cols="12"
                        >
                          <v-col
                            class="py-0"
                            cols="12"
                          >
                            <div>{{ education.name }}</div>
                            <div>{{ education.institution }}</div>
                            <div>
                              {{ education.startYear }} - {{
                                education.endYear || $t('candidate.background.presentDay')
                              }}
                              <span>({{ hasCertificateText(education.hasCertificate) }})</span>
                            </div>
                            <VDivider
                              v-if="index + 1 < details.educations.length"
                              class="my-3"
                            />
                          </v-col>
                        </v-row>
                      </div>
                    </v-expand-transition>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-navigation-drawer>
</template>

<script>
import formatExperienceTimeInTextI18n from '@/application/util/experienceTime.js';
import { genderToIcon } from '@/application/util/gender.js';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import AddressDisplay from '@/modules/address/components/AddressDisplay.vue';
import { shiftCandidate } from '@/modules/shift/api/shift.js';
import ShiftCandidateStatus from '@/modules/shift/components/ShiftCandidateStatus.vue';
import dayjs from '@/plugins/dayjs';
import KSubtitle from '@/components/layout/KSubtitle.vue';
import ShiftCandidateHandlingButton from '@/modules/shift/components/ShiftCandidateHandlingButton.vue';

export default {
  name: 'ShiftCandidateDrawer',
  components: {
    ShiftCandidateHandlingButton,
    KTextDisplay,
    KSubtitle,
    ShiftCandidateStatus,
    AddressDisplay,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    candidateId: {
      type: Number,
    },
    shiftHandlingId: {
      type: Number,
    },
    shiftStatusId: {
      type: Number,
    },
    matchModeId: {
      type: Number,
    },
  },
  data() {
    return {
      drawer: false,
      details: null,
      tab: 0,
      showExperience: false,
      showEducation: false,
    };
  },
  computed: {
    hasProfilePicture() {
      return !!this.details?.profilePicture?.url;
    },
    genderIcon() {
      return genderToIcon(this.details.genderId);
    },
    age() {
      const age = dayjs().diff(dayjs(this.details.dateOfBirth), 'year');
      return this.details.dateOfBirth && `${age} ${this.$tc('global.timeUnits.year', 1)}`;
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value) this.fetchDetails();
      },
      immediate: true,
    },
    drawer() {
      this.$emit('input', this.drawer);
    },
  },
  methods: {
    formatExperienceTimeInTextI18n,
    async fetchDetails() {
      this.details = null;
      if (!this.candidateId) {
        return;
      }
      const request = await shiftCandidate(this.$route.params.shiftId, this.candidateId);
      this.details = request.data.data;
      this.drawer = true;
    },
    hasCertificateText(hasCertificate) {
      if (hasCertificate === false) {
        return this.$t('candidate.background.fields.educationHasCertificate.didNotFinish');
      }
      if (hasCertificate) {
        return this.$t('candidate.background.fields.educationHasCertificate.finished');
      }
      return this.$t('candidate.background.fields.educationHasCertificate.notYetFinished');

    },
    handleStatusChange() {
      this.$emit('change');
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.expand-icon {
  :deep(&.open) {
    transform: rotate(-180deg);
  }
}
</style>
