<template>
  <v-row>
    <k-subtitle subtitle-class="mt-4">
      <template>{{ $t('shift.invited.title') }}</template>
      <template #subtitle>
        {{ $t('shift.invited.subtitle') }}
      </template>
    </k-subtitle>
    <v-col cols="12">
      <k-crud-table
        ref="table"
        v-bind="$attrs"
        :headers="headers"
        :index-request="indexRequest"
        language-prefix="shift.candidateFields"
        resource="position"
        item-key="candidateId"
        v-on="$listeners"
      >
        <template #item.status="{ item }">
          <ShiftCandidateStatus :value="item.shiftCandidateStatusId" />
        </template>
        <template #item.travelingTime="{ item }">
          <ShiftTravelingTime
            v-if="item.travelingTime && item.maxTravelingTime"
            :max-traveling-time="item.maxTravelingTime"
            :traveling-time="item.travelingTime"
          />
        </template>
        <template #item.shiftCandidateStatusId="{ item }">
          {{ $t(`shift.candidateStatus.${item.shiftCandidateStatusId}`) }}
        </template>
      </k-crud-table>
    </v-col>
  </v-row>
</template>

<script>
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KSubtitle from '@/components/layout/KSubtitle.vue';
import { invitedCandidates } from '@/modules/shift/api/shift.js';
import ShiftCandidateStatus from '@/modules/shift/components/ShiftCandidateStatus.vue';
import ShiftTravelingTime from '@/modules/shift/components/ShiftTravelingTime.vue';

export default {
  name: 'InvitedCandidateTable',
  components: {
    ShiftCandidateStatus,
    ShiftTravelingTime,
    KCrudTable,
    KSubtitle,
  },
  inheritAttrs: false,
  props: {
    shiftHandlingId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    headers() {
      return [
        {
          value: 'status',
          sortable: false,
          text: ' ',
          width: 10,
          cellClass: 'no-table-separator',
        },
        {
          value: 'candidateName',
          cellClass: 'no-table-separator',
        },
        {
          value: 'travelingTime',
        },
        {
          value: 'shiftCandidateStatusId',
          language: 'status',
        },
      ];
    },
  },
  methods: {
    reload() {
      this.$refs.table.reloadPaginator();
    },
    indexRequest() {
      return invitedCandidates(this.$route.params.shiftId, ...arguments);
    },
    percentageToColor(percentage) {
      if (percentage < 50) {
        return 'error';
      }
      if (percentage < 80) {
        return 'warning';
      }
      return 'success';
    },
  },
};
</script>
