<template>
  <v-row class="align-stretch">
    <v-col>
      <k-fancy-card
        color="pink"
        icon="$employer"
      >
        <template
          v-if="shift"
          #default
        >
          <div>{{ shift.employer.name }}</div>
          <div class="font-italic">
            {{ shift.location.name }}, {{ shift.department.name }}
          </div>
        </template>
      </k-fancy-card>
    </v-col>
    <v-col>
      <k-fancy-card
        color="orange"
        icon="$shift"
      >
        <template
          v-if="shift"
          #default
        >
          <div class="mb-2">
            {{ shift.position.name }}
          </div>
          <div class="font-italic">
            {{ period.date }}
          </div>
          <div class="font-italic">
            {{ period.time }}
          </div>
        </template>
      </k-fancy-card>
    </v-col>
    <v-col>
      <k-fancy-card
        color="purple"
        icon="$settings"
      >
        <template
          v-if="shift"
          #default
        >
          <div class="font-weight-bold">
            {{ $t('shift.fields.matchModeId') }}
          </div>
          <div class="font-italic">
            {{ $t(`shift.shiftMatchMode.${shift.matchModeId}`) }}
          </div>
        </template>
      </k-fancy-card>
    </v-col>
  </v-row>
</template>

<script>
import KFancyCard from '@/components/layout/KFancyCard.vue';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'ShiftCards',
  components: { KFancyCard },
  props: {
    shift: {
      type: Object,
    },
  },
  computed: {
    period() {
      const fromTime = dayjs(this.shift.date + this.shift.from).format('LT');
      const toTime = dayjs(this.shift.date + this.shift.to).format('LT');

      return {
        date: this.shift.date ? dayjs(this.shift.date).format('LL') : '',
        time: `${fromTime} ${this.$t('global.till')} ${toTime}`,
      };
    },
  },
};
</script>
