<template>
  <PageLayout>
    <template #header>
      <k-title>{{ $t('shift.fillTitle') }}</k-title>
    </template>
    <template #default>
      <ShiftCards
        :shift="shift"
        class="mt-3"
      />
      <v-alert
        v-if="shift && shift.candidate"
        :value="shift && shift.shiftStatusId === shiftStatus.FULFILLED"
        class="mt-4"
        transition="scale-transition"
        type="success"
      >
        <div class="text-h3">
          {{ $t('shift.fulfilledBy', { name: shift.candidate.name }) }}
        </div>
        <div class="caption">
          {{ statusDate }}
        </div>
      </v-alert>
      <InvitedCandidateTable
        v-if="matchModeId === shiftMatchMode.CHOOSE"
        ref="invitedCandidates"
        :shift-handling-id="shiftHandlingId"
        class="mt-3 elevation-0"
        @change="onInvitedChange"
        @click:row="openCandidateDrawer"
      />
      <VDivider v-if="!shift || shift.shiftStatusId !== shiftStatus.FULFILLED" />
      <ProposedCandidateTable
        v-if="!shift || shift.shiftStatusId !== shiftStatus.FULFILLED"
        ref="proposedCandidates"
        :direct-placing="matchModeId === shiftMatchMode.STATIC"
        :show-select="shift && shift.shiftStatusId !== shiftStatus.FULFILLED"
        class="mt-3 elevation-0"
        @change="onProposedChange"
        @click:row="openCandidateDrawer"
      />
      <ShiftCandidateDrawer
        v-model="candidateDrawer"
        :candidate-id="candidateId"
        :shift-handling-id="shiftHandlingId"
        :match-mode-id="matchModeId"
        :shift-status-id="shiftStatusId"
        @change="onCandidateChange"
      />
    </template>
  </PageLayout>
</template>

<script>
import { shiftMatchMode } from '@/application/enums/shiftMatchMode.js';
import { shiftStatus } from '@/application/enums/shiftStatus.js';
import eventBus from '@/application/eventBus.ts';
import PageLayout from '@/components/layout/PageLayout.vue';
import InvitedCandidateTable from '@/modules/shift/components/InvitedCandidateTable.vue';
import ProposedCandidateTable from '@/modules/shift/components/ProposedCandidateTable.vue';
import ShiftCandidateDrawer from '@/modules/shift/components/ShiftCandidateDrawer.vue';
import dayjs from '@/plugins/dayjs';
import KTitle from '@/components/layout/KTitle.vue';
import ShiftCards from '@/modules/shift/components/ShiftCards.vue';

export default {
  name: 'Details',
  components: {
    ShiftCards,
    KTitle,
    ShiftCandidateDrawer,
    InvitedCandidateTable,
    ProposedCandidateTable,
    PageLayout,
  },
  props: {
    shift: {
      type: Object,
    },
    getShift: {
      type: Function,
    },
  },
  data() {
    return {
      candidateDrawer: false,
      candidateId: 0,
    };
  },
  computed: {
    matchModeId() {
      return this.shift?.matchModeId;
    },
    shiftStatusId() {
      return this.shift?.shiftStatusId;
    },
    shiftHandlingId() {
      return this.shift?.shiftHandlingId;
    },
    shiftMatchMode: () => shiftMatchMode,
    shiftStatus: () => shiftStatus,
    statusDate() {
      return dayjs(this.shift.statusDate).format('LLL');
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'shift.index' },
        text: this.$tc('shift.title', 2),
      },
      {
        exact: true,
        to: { name: 'shift.show' },
        text: this.$t('shift.fillTitle'),
      }],
    );
  },
  methods: {
    reload() {
      this.$refs.invitedCandidates.reload();
      this.$refs.proposedCandidates.reload();
    },
    onProposedChange() {
      this.$refs.invitedCandidates.reload();
    },
    onInvitedChange() {
      this.getShift();
    },
    onCandidateChange() {
      this.getShift();
      this.reload();
    },
    openCandidateDrawer(shiftCandidate) {
      this.candidateId = shiftCandidate.candidateId;
      this.candidateDrawer = true;
    },
  },
};
</script>
