<template>
  <dropdown-button
    v-if="showDropdownButton"
    @click.stop="handleGenericClick"
  >
    {{ genericText || $t('shift.actions.default') }}
    <template #list>
      <v-list>
        <v-list-item
          link
          @click="invitationAccept"
        >
          {{ $t('shift.actions.invitationAccept') }}
        </v-list-item>
        <v-list-item
          link
          @click="invitationRefuse"
        >
          {{ $t('shift.actions.invitationRefuse') }}
        </v-list-item>
      </v-list>
    </template>
  </dropdown-button>
  <v-btn
    v-else-if="genericText"
    color="primary"
    depressed
    tile
    @click="handleGenericClick"
  >
    {{ genericText }}
  </v-btn>
</template>

<script>
import {
  acceptCandidateInvitation,
  inviteCandidate,
  placeCandidate,
  proposeCandidate,
  refuseCandidateInvitation,
} from '@/modules/shift/api/shift.js';
import { shiftHandling } from '@/application/enums/shiftHandling.js';
import { shiftCandidateStatus } from '@/application/enums/shiftCandidateStatus.js';
import { shiftMatchMode } from '@/application/enums/shiftMatchMode.js';
import DropdownButton from '@/components/DropdownButton.vue';
import { shiftStatus } from '@/application/enums/shiftStatus.js';

export default {
  name: 'ShiftCandidateHandlingButton',
  components: { DropdownButton },
  props: {
    shiftHandlingId: {
      type: Number,
      required: true,
    },
    matchModeId: {
      type: Number,
      required: true,
    },
    candidateId: {
      type: Number,
      required: true,
    },
    shiftId: {
      type: Number,
      required: true,
    },
    shiftCandidateStatusId: {
      type: Number,
      required: true,
    },
    shiftStatusId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isProposable() {
      return this.matchModeId === shiftMatchMode.CHOOSE && this.shiftHandlingId === shiftHandling.PROPOSE && this.shiftCandidateStatusId === shiftCandidateStatus.INVITATION_ACCEPTED;
    },
    isPlaceable() {
      return this.matchModeId === shiftMatchMode.STATIC ||
          (this.matchModeId === shiftMatchMode.CHOOSE && this.shiftHandlingId === shiftHandling.PLACE && this.shiftCandidateStatusId === shiftCandidateStatus.INVITATION_ACCEPTED);
    },
    isInviteable() {
      return this.matchModeId === shiftMatchMode.CHOOSE && this.shiftCandidateStatusId === shiftCandidateStatus.MATCHED;
    },
    isAcceptable() {
      return this.matchModeId === shiftMatchMode.CHOOSE && [shiftStatus.NEW, shiftStatus.INVITED].includes(this.shiftStatusId);
    },
    isRefuseable() {
      return this.matchModeId === shiftMatchMode.CHOOSE && [shiftStatus.NEW, shiftStatus.INVITED].includes(this.shiftStatusId);
    },
    showDropdownButton() {
      return this.isAcceptable && this.isRefuseable;
    },
    genericText() {
      if (this.isPlaceable) return this.$t('shift.actions.place');
      if (this.isInviteable) return this.$t('shift.actions.invite');
      if (this.isProposable) return this.$t('shift.actions.propose');
      return '';
    },
  },
  methods: {
    async invite() {
      await inviteCandidate(this.shiftId, [this.candidateId]);
      this.$emit('change');
    },
    async place() {
      await placeCandidate(this.shiftId, this.candidateId);
      this.$emit('change');
    },
    async propose() {
      await proposeCandidate(this.shiftId, this.candidateId);
      this.$emit('change');
    },
    async invitationAccept() {
      await acceptCandidateInvitation(this.shiftId, [this.candidateId]);
      this.$emit('change');
    },
    async invitationRefuse() {
      await refuseCandidateInvitation(this.shiftId, [this.candidateId]);
      this.$emit('change');
    },
    handleGenericClick() {
      if (this.isPlaceable) return this.place();
      if (this.isInviteable) return this.invite();
      if (this.isProposable) return this.propose();
      return false;
    },
  },
};
</script>
