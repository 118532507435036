import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (page, perPage, search, sortBy, descending, params) => {
  params = {
    ...!!params.employerId && { employerId: params.employerId },
    ...!!params.from && { from: `${params.from} ${params?.time ? params.time : '00:00:00'}` },
    ...!!params.to && { to: `${params.to} ${params?.time ? params.time : '23:59:00'}` },
    ...!!params.shiftStatuses && { shiftStatuses: Array.isArray(params.shiftStatuses) ? params.shiftStatuses : [params.shiftStatuses] },
  };
  return getPaginated('shift', page, perPage, search, sortBy, descending, params);
};

/**
 * @param shiftId {number}
 */
export const show = (shiftId) => {
  return get(`shift/${shiftId}`);
};
/**
 * @param shiftId {number}
 */
export const showMatching = (shiftId) => {
  return get(`shift/${shiftId}/match`);
};

/**
 * @param shiftId {number}
 * @param candidateId {number}
 */
export const shiftCandidate = (shiftId, candidateId) => {
  return get(`shift/${shiftId}/candidate/${candidateId}`);
};

/**
 * @param shiftId {number}
 */
export const invitedCandidates = (shiftId, page, perPage, search, sortBy, descending, params) => {
  return getPaginated(`shift/${shiftId}/invited`, page, perPage, search, sortBy, descending, params);
};

/**
 * @param shiftId {number}
 */
export const proposedCandidates = (shiftId, page, perPage, search, sortBy, descending, params) => {
  return getPaginated(`shift/${shiftId}/proposed`, page, perPage, search, sortBy, descending, params);
};

/**
 * @param shiftId {number}
 * @param candidateId {number}
 */
export const placeCandidate = (shiftId, candidateId) => {
  return post(`shift/${shiftId}/fulfill`, {
    candidateId,
  });
};

/**
 * @param shiftId {number}
 * @param candidateId {number}
 */
export const proposeCandidate = (shiftId, candidateId) => {
  return post(`shift/${shiftId}/propose`, {
    candidateId,
  });
};

/**
 * @param shiftId {number}
 * @param candidates {array<number>}
 */
export const inviteCandidate = (shiftId, candidates) => {
  return post(`shift/${shiftId}/invite`, {
    candidates,
  });
};

/**
 * @param shiftId {number}
 * @param candidates {array<number>}
 */
export const acceptCandidateInvitation = (shiftId, candidates) => {
  return post(`shift/${shiftId}/invitation-accept`, {
    candidates,
  });
};

/**
 * @param shiftId {number}
 * @param candidates {array<number>}
 */
export const refuseCandidateInvitation = (shiftId, candidates) => {
  return post(`shift/${shiftId}/invitation-refuse`, {
    candidates,
  });
};

/**
 * @param shift {Shift}
 */
export const create = (shift) => {
  return post('shift', shift);
};

/**
 * @param shift {Shift}
 */
export const update = (shift) => {
  return put(`shift/${shift.id}`, shift);
};

/**
 * @param shiftId {number}
 */
export const remove = (shiftId) => {
  return destroy(`shift/${shiftId}`);
};

/**
 * @param shiftId {number}
 */
export const withdraw = (shiftId) => {
  return post(`shift/${shiftId}/withdraw`);
};
