<template>
  <v-col cols="12">
    <v-row no-gutters>
      <h2
        v-bind="$attrs"
        :class="titleClass"
        class="text-h3 primary--text text--darken-3"
        v-on="$listeners"
      >
        <slot />
      </h2>
    </v-row>
    <v-row no-gutters>
      <div
        v-bind="$attrs"
        :class="subtitleClass"
        class="subtitle-1"
        v-on="$listeners"
      >
        <slot name="subtitle" />
      </div>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'KSubtitle',
  inheritAttrs: false,
  props: {
    titleClass: { type: String },
    subtitleClass: { type: String },
  },
};
</script>
