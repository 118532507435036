<template>
  <v-card height="100%">
    <v-sheet
      :class="color"
      class="pa-7"
      rounded
      elevation="6"
    >
      <v-icon color="white">
        {{ icon }}
      </v-icon>
    </v-sheet>
    <v-card-text class="text-right">
      <slot>
        <VSkeletonLoader
          type="sentences"
          class="skeleton-right"
        />
      </slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'KFancyCard',
  props: {
    icon: {
      required: true,
    },
    color: {
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$icon-sheet-width: 80px;
.v-card {
  min-height: 100px;

  .v-sheet {
    left:       16px;
    position:   absolute;
    text-align: center;
    top:        -18px;
    width:      $icon-sheet-width;
  }

  .v-card__text {
    padding-left: 16px + $icon-sheet-width + 16px;
  }
}
</style>

<style>
.skeleton-right .v-skeleton-loader__text {
  margin-left: auto;
}
</style>
