export const shiftCandidateStatus = {
  INVITED: 1,
  INVITATION_ACCEPTED: 2,
  INVITATION_REFUSED: 3,
  PLACED: 4,
  MATCHED: 5,
  REFUSED_BY_INTERMEDIARY: 6,
  REFUSED_BY_EMPLOYER: 7,
  REFUSED_BY_CONFLICT: 8, // After rematching, shift is no longer possible
  REFUSED_BY_WITHDRAWAL: 9,
  NOT_SELECTED: 10, // Another candidate is selected for this shift
  PROPOSED_TO_EMPLOYER: 11,
};
