<template>
  <div>
    <span>{{ travelingTime }} {{ $tc('global.timeUnits.minute', maxTravelingTime) }}</span>
    <span
      v-if="overTime"
      class="error--text"
    > (+ {{ overTime }} {{ $tc('global.timeUnits.minute', overTime) }})</span>
  </div>
</template>

<script>
export default {
  name: 'ShiftTravelingTime',
  props: {
    travelingTime: {
      required: true,
      type: Number,
    },
    maxTravelingTime: {
      required: true,
      type: Number,
    },
  },
  computed: {
    overTime() {
      if (this.maxTravelingTime > this.travelingTime) return 0;
      return this.travelingTime - this.maxTravelingTime;
    },
  },
};
</script>
