<template>
  <v-icon
    v-bind="$attrs"
    :color="color"
  >
    {{ icon }}
  </v-icon>
</template>

<script>
import { shiftCandidateStatus } from '@/application/enums/shiftCandidateStatus.js';

export default {
  name: 'ShiftCandidateStatus',
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    icon() {
      switch (this.value) {
      case shiftCandidateStatus.INVITED:
      case shiftCandidateStatus.PROPOSED_TO_EMPLOYER:
      case shiftCandidateStatus.MATCHED:
        return '$waiting';
      case shiftCandidateStatus.PLACED:
        return '$shiftPlaced';
      case shiftCandidateStatus.INVITATION_ACCEPTED:
        return '$complete';
      case shiftCandidateStatus.INVITATION_REFUSED:
      case shiftCandidateStatus.REFUSED_BY_INTERMEDIARY:
      case shiftCandidateStatus.REFUSED_BY_EMPLOYER:
      case shiftCandidateStatus.REFUSED_BY_CONFLICT:
      case shiftCandidateStatus.REFUSED_BY_WITHDRAWAL:
      case shiftCandidateStatus.NOT_SELECTED:
        return '$clear';
      default:
        return '$unknown';
      }
    },
    color() {
      switch (this.value) {
      case shiftCandidateStatus.INVITED:
      case shiftCandidateStatus.PROPOSED_TO_EMPLOYER:
      case shiftCandidateStatus.MATCHED:
        return 'info';
      case shiftCandidateStatus.PLACED:
      case shiftCandidateStatus.INVITATION_ACCEPTED:
        return 'success';
      case shiftCandidateStatus.INVITATION_REFUSED:
      case shiftCandidateStatus.REFUSED_BY_INTERMEDIARY:
      case shiftCandidateStatus.REFUSED_BY_EMPLOYER:
      case shiftCandidateStatus.REFUSED_BY_CONFLICT:
      case shiftCandidateStatus.REFUSED_BY_WITHDRAWAL:
      case shiftCandidateStatus.NOT_SELECTED:
        return 'error';
      default:
        return 'error';
      }
    },
  },
};
</script>
