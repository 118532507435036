import dayjs from '@/plugins/dayjs';

export default function formatExperienceTimeInTextI18n(dateStart, dateEnd) {
  if (!dateEnd) {
    dateEnd = dayjs().toString();
  }
  const durationInMonths = Math.abs(dayjs(dateStart).diff(dayjs(dateEnd), 'month'));
  const amountOfYears = Math.floor(durationInMonths / 12);
  const remainingMonths = durationInMonths - (amountOfYears * 12);
  let durationConverted = '';
  if (amountOfYears > 0) {
    durationConverted += `${amountOfYears} ${this.$tc('global.timeUnits.year', amountOfYears)}`;
  }
  if (amountOfYears > 0 && remainingMonths > 0) {
    durationConverted += ` ${this.$t('global.timeUnits.seperator')} `;
  }
  if (remainingMonths > 0 || amountOfYears === 0) {
    durationConverted += `${remainingMonths} ${this.$tc('global.timeUnits.month', remainingMonths)}`;
  }
  return `(~ ${durationConverted})`;
}
