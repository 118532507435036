<template>
  <v-row>
    <k-subtitle subtitle-class="mt-4">
      <template>{{ $t('shift.proposed.title') }}</template>
      <template #subtitle>
        {{ $t('shift.proposed.subtitle') }}
      </template>
    </k-subtitle>
    <v-col cols="12">
      <k-crud-table
        ref="table"
        v-model="selected"
        :headers="headers"
        v-bind="$attrs"
        :index-request="indexRequest"
        language-prefix="shift.candidateFields"
        resource="position"
        item-key="candidateId"
        :single-select="directPlacing"
        v-on="$listeners"
      >
        <template #item.matchPercentage="{ item }">
          <v-chip :color="percentageToColor(item.matchPercentage)">
            {{ item.matchPercentage }} %
          </v-chip>
        </template>
        <template #item.travelingTime="{ item }">
          <ShiftTravelingTime
            v-if="item.travelingTime && item.maxTravelingTime"
            :max-traveling-time="item.maxTravelingTime"
            :traveling-time="item.travelingTime"
          />
        </template>
      </k-crud-table>
      <v-scale-transition>
        <v-btn
          v-if="selected.length && directPlacing"
          tile
          depressed
          class="mt-3"
          color="primary"
          @click="place"
        >
          {{ $t('shift.actions.place') }}
        </v-btn>
        <v-btn
          v-else-if="selected.length"
          tile
          depressed
          class="mt-3"
          color="primary"
          @click="invite"
        >
          {{ $t('shift.actions.invite') }}
        </v-btn>
      </v-scale-transition>
    </v-col>
  </v-row>
</template>

<script>
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KSubtitle from '@/components/layout/KSubtitle.vue';
import { inviteCandidate, placeCandidate, proposedCandidates } from '@/modules/shift/api/shift.js';
import ShiftTravelingTime from '@/modules/shift/components/ShiftTravelingTime.vue';

export default {
  name: 'ProposedCandidateTable',
  components: {
    ShiftTravelingTime,
    KCrudTable,
    KSubtitle,
  },
  inheritAttrs: false,
  props: {
    /**
     * Directly placing the candidates instead of inviting them
     */
    directPlacing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          value: 'matchPercentage',
          width: 10,
          cellClass: 'no-table-separator',
        },
        {
          value: 'candidateName',
          cellClass: 'no-table-separator',
        },
        {
          value: 'travelingTime',
        },
      ];
    },
  },
  methods: {
    async invite() {
      await inviteCandidate(this.$route.params.shiftId, this.selected.map(candidate => candidate.candidateId));
      this.$refs.table.reloadPaginator();
      this.$emit('change');
      this.selected = [];
    },
    async place() {
      await placeCandidate(this.$route.params.shiftId, this.selected[0].candidateId);
      this.$refs.table.reloadPaginator();
      this.$emit('change');
      this.selected = [];
    },
    reload() {
      this.$refs.table.reloadPaginator();
    },
    indexRequest() {
      return proposedCandidates(this.$route.params.shiftId, ...arguments);
    },
    percentageToColor(percentage) {
      if (percentage < 50) {
        return 'error';
      }
      if (percentage < 80) {
        return 'warning';
      }
      return 'success';
    },
  },
};
</script>
